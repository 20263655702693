// 57
export default [
  {
    _name: "CSidebarNav",
    _children: [
      {
        id: 34,
        _name: "CSidebarNavDropdown",
        name: "Dashboard",
        key: "dashboard",
        icon: "cil-chart-line",
        _children: [
          {
            id: 36,
            _name: "CSidebarNavItem",
            name: "Customer",
            key: "customer-analysis",
            to: "/customer-analysis",
          },
          {
            id: 45,
            _name: "CSidebarNavItem",
            name: "Prospect",
            key: "prospect-chart",
            to: "/prospect-chart",
          },
          {
            id: 40,
            _name: "CSidebarNavItem",
            name: "Channel",
            to: "/channel-analysis",
            key: "channel-analysis",
            is_sub_menu: 1,
          },
          {
            id: 46,
            _name: "CSidebarNavItem",
            name: "Coupon",
            key: "coupon-chart",
            to: "/coupon-chart",
          },
          {
            id: 59,
            _name: "CSidebarNavItem",
            name: "Ticket",
            to: "/ticket-chart",
            key: "ticket-chart",
            is_sub_menu: 1,
          },
          {
            id: 45,
            // id: 52,
            _name: "CSidebarNavItem",
            name: "Inventory",
            key: "inventory-dashboard",
            to: "/Inventory",
          },
          {
            id: 44,
            _name: "CSidebarNavItem",
            name: "Redeem",
            to: "/redeem-chart",
            key: "redeem-chart",
            is_sub_menu: 1,
          },
          {
            id: 41,
            _name: "CSidebarNavItem",
            name: "Promotion",
            to: "/promotion-chart",
            key: "promotion-chart",
            is_sub_menu: 1,
          },
          {
            id: 50,
            _name: "CSidebarNavItem",
            name: "Customer Behavior",
            to: "/bundle-product",
            key: "bundle-product",
            is_sub_menu: 1,
          },
          {
            id: 42,
            _name: "CSidebarNavItem",
            name: "Service",
            to: "/service-chart",
            key: "service-chart",
            is_sub_menu: 1,
          },
        ],
      },
      {
        id: 55,
        _name: "CSidebarNavDropdown",
        name: "Custom report",
        key: "custom_report",
        icon: "cil-chart-line",
      },
      {
        id: 56,
        _name: "CSidebarNavItem",
        name: "Transaction redeem point",
        to: "/transaction-redeem-point",
        icon: "cil-chart-line",
        key: "transaction-redeem-point",
      },

      {
        id: 1,
        _name: "CSidebarNavDropdown",
        name: "Content2",
        icon: "cil-loop-circular",
        key: "Content2",

        _children: [
          {
            id: 2,
            _name: "CSidebarNavItem",
            name: "Product Banner",
            key: "test",
            to: "/productbanner",
          },
          {
            id: 3,
            _name: "CSidebarNavItem",
            name: "Product Mobile",
            key: "product_mobile",
            to: "/productmobile",
          },
          {
            id: 4,
            _name: "CSidebarNavItem",
            name: "Sales Channel",
            key: "sales_channel",
            to: "/saleschannel",
          },
          {
            id: 5,
            _name: "CSidebarNavItem",
            name: "Notification",
            to: "/notification",
            // icon: "cil-bell",
            key: "notification",
          },
          {
            id: 6,
            _name: "CSidebarNavItem",
            name: "Video",
            to: "/video",
            // icon: "cil-video",
            key: "video",
          },
        ],
      },
      {
        id: 7,
        _name: "CSidebarNavDropdown",
        name: "Content",
        key: "banner",

        icon: "cil-newspaper",
        _children: [
          {
            id: 8,
            _name: "CSidebarNavItem",
            name: "Banner",
            key: "banner",
            to: "/banner",
          },
          {
            id: 9,
            _name: "CSidebarNavItem",
            name: "News/Promotion News",
            to: "/promotionnews",
            // icon: "cil-newspaper",
            key: "news_promotion_news",
            is_sub_menu: 1,
          },
          {
            id: 10,
            _name: "CSidebarNavItem",
            name: "Category",
            is_sub_menu: 1,
            to: "/category",
          },
        ],
      },
      {
        id: 11,
        _name: "CSidebarNavDropdown",
        name: "Customer",
        icon: "cil-people",
        key: "customer",
        _children: [
          {
            id: 48,
            _name: "CSidebarNavItem",
            name: "Customer",
            key: "customer",
            to: "/report/customer",
            is_sub_menu: 1,
          },
          {
            id: 47,
            _name: "CSidebarNavItem",
            name: "Segment",
            to: "/segment",
            key: "segment",
            is_sub_menu: 1,
          },
          {
            id: 51,
            _name: "CSidebarNavItem",
            name: "Custom Object",
            to: "/custom-object",
            // icon: "cil-layers",
            // key: "custom_object",
          },
        ],
      },
      {
        id: 12,
        _name: "CSidebarNavDropdown",
        name: "Transaction",
        icon: "cil-credit-card",
        key: "customer",
        _children: [
          {
            id: 69,
            _name: "CSidebarNavItem",
            name: "Transaction",
            to: "/report/transaction",
            icon: "cil-credit-card",
            key: "transaction",
          },
          {
            id: 68,
            _name: "CSidebarNavItem",
            name: "Transaction Pending",
            to: "/report/transaction-pending",
            icon: "cil-reload",
            key: "transaction",
          },
        ],
      },

      {
        id: 13,
        _name: "CSidebarNavDropdown",
        name: "Product",
        icon: "cil-list",
        key: "ProductDropdown",
        _children: [
          {
            id: 13,
            _name: "CSidebarNavItem",
            name: "Product",
            to: "/product",
            key: "product",
          },
          {
            id: 58,
            _name: "CSidebarNavItem",
            name: "Product Category",
            to: "/product-category",
            key: "product_category",
          },
          {
            id: 66,
            _name: "CSidebarNavItem",
            name: "Product Collection",
            to: "/product-collection",
            key: "product-collection",
          },
        ],
      },
      // {
      //   id: 13,
      //   _name: "CSidebarNavItem",
      //   name: "Product",
      //   to: "/product",
      //   icon: "cil-list",
      //   key: "product",
      //   is_sub_menu: 1,
      // },

      {
        id: 14,
        _name: "CSidebarNavDropdown",
        name: "Privilege",
        icon: "cil-gift",
        key: "privilege",

        _children: [
          {
            id: 15,
            _name: "CSidebarNavItem",
            name: "Privilege",
            to: "/privilegecode",
            key: "privilege_code",
          },
          {
            id: 16,
            _name: "CSidebarNavItem",
            name: "Beauty Passport",
            to: "/stampcard",
            key: "beauty_passport",
          },
          {
            id: 17,
            _name: "CSidebarNavItem",
            name: "Coupon",
            to: "/coupon",
            key: "coupon",
          },
          {
            id: 39,
            _name: "CSidebarNavItem",
            name: "Promotions",
            to: "/promotions",
            key: "promotions",
          },
          {
            id: 49,
            _name: "CSidebarNavItem",
            name: "Friend Get Friend",
            to: "/friendgetfriend",
            key: "friend_get_friend",
          },
          {
            id: 57,
            _name: "CSidebarNavItem",
            name: "Mission",
            to: "/mission",
            key: "mission",
          },
        ],
      },

      {
        id: 18,
        _name: "CSidebarNavItem",
        name: "Campaign Forms",
        to: "/form",
        icon: "cil-columns",
        key: "campaign_forms",
      },
      {
        id: 33,
        _name: "CSidebarNavItem",
        name: "Booking",
        to: "/booking",
        icon: "cil-list",
        key: "booking",
      },
      {
        id: 19,
        _name: "CSidebarNavDropdown",
        name: "Marketing Automation",
        icon: "cil-loop-circular",
        key: "marketing_automation",

        _children: [
          {
            id: 20,
            _name: "CSidebarNavItem",
            name: "Automation",
            to: "/automation",
            key: "automation",
          },
          {
            id: 21,
            _name: "CSidebarNavItem",
            name: "Send Message",
            to: "/automation/sendmessage",
            key: "send_message",
          },
        ],
      },

      {
        id: 22,
        _name: "CSidebarNavItem",
        name: "Extra Point",
        to: "/extrapoint",
        icon: "cil-layers",
        key: "extra_point",
      },
      {
        id: 23,
        _name: "CSidebarNavDropdown",
        name: "Redeem",
        icon: "cil-puzzle",
        key: "redeem",
        _children: [
          {
            id: 61,
            _name: "CSidebarNavItem",
            name: "Redeem",
            key: "redeem_detail",
            to: "/redeem",
          },
          {
            id: 60,
            _name: "CSidebarNavItem",
            name: "Redeem Report",
            key: "redeem_report",
            to: "/redeem-report",
          },
        ],
      },

      {
        id: 59,
        _name: "CSidebarNavItem",
        name: "Ticket",
        to: "/ticket-activity",
        icon: "cil-columns",
        key: "ticket-activity",
      },
      {
        id: 24,
        _name: "CSidebarNavItem",
        name: "Right Request",
        to: "/allrightrequest",
        icon: "cil-layers",
        key: "all_right_request",
      },

      {
        id: 25,
        _name: "CSidebarNavItem",
        name: "Activity Log",
        to: "/activitylog",
        icon: "cil-list",
        key: "activity_log",
      },
      {
        id: 43,
        _name: "CSidebarNavItem",
        name: "Customer Report",
        to: "/customer-report-file",
        icon: "cil-file",
        key: "customer_report",
      },
      {
        id: 26,
        _name: "CSidebarNavDropdown",
        name: "Configuration",
        icon: "cil-settings",
        key: "configuration",
        _children: [
          {
            id: 27,
            _name: "CSidebarNavItem",
            name: "Member Tier",
            to: "/membertier",
            // icon: "cil-layers",
            key: "member_tier",
          },
          {
            id: 54,
            _name: "CSidebarNavItem",
            name: "Point Setting",
            to: "/point-setting",
            // icon: "cil-layers",
            key: "point_setting",
          },

          // {
          //   id: 28,
          //   _name: "CSidebarNavItem",
          //   name: "Staff",
          //   to: "/manageStaff",
          //   // icon: "cil-people",
          //   key: "manageStaff",
          // },
          {
            id: 35,
            _name: "CSidebarNavItem",
            name: "Transaction Code",
            to: "/transaction-code",
            key: "transaction-code",
          },
          {
            id: 53,
            _name: "CSidebarNavItem",
            name: "Random reward",
            to: "/random-reward",
            key: "random-reward",
          },
          {
            id: 29,
            _name: "CSidebarNavItem",
            name: "Branch",
            to: "/branch-management",
            // icon: "cil-home",
            key: "manageBranch",
          },
          {
            id: 38,
            _name: "CSidebarNavItem",
            name: "Branch Type",
            to: "/branchType",
            // icon: "cil-layers",
            key: "branch_type",
          },
          {
            id: 70,
            _name: "CSidebarNavItem",
            name: "User Permission",
            to: "/user-permission",
            key: "user_permission",
          },
          // {
          //   id: 30,
          //   _name: "CSidebarNavItem",
          //   name: "Admin",
          //   to: "/admin-management",
          //   key: "permission Admin",
          // },
          {
            id: 31,
            _name: "CSidebarNavItem",
            name: "Role",
            to: "/role-management",
            key: "permission Role",
          },

          {
            id: 32,
            _name: "CSidebarNavItem",
            name: "Setting",
            to: "/setting",
            key: "setting",
          },
        ],
      },
    ],
  },
];
